import * as Auth from 'j-toker';
require('../assets/js/jquery.cookie')
import Authorization from './authorization.js.erb';
window.jQuery = window.$ = require('jquery');

$.authorize = new Authorization();

$.ajaxSetup({
  beforeSend: function(xhr, settings) {
    // append outbound auth headers

    // xhr.setRequestHeader("Content-Type","application/json");
    // xhr.setRequestHeader("Accept","application/json");

    // console.log('Setting', settings)
    // console.log('XHR', xhr)
    // console.log('Response', xhr.getAllResponseHeaders())

    $.auth.appendAuthHeaders(xhr, settings);
    // now do whatever you want
  }
});


$.authorize.pubSubCallbacks()
$.authorize.modalTriggers();
$.authorize.logTriggers();


$(document).on('load, turbolinks:load', function() {
  $.authorize.handleValidate();
});

