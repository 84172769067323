import Rails from "@rails/ujs"

export default class Authorization {
  constructor(login_btn='#btn-login') {
    this._API_URL  = 'http://localhost:8080';

    if(process.env.RAILS_ENV == 'staging')
      this._API_URL  = 'https://staging.lotto.koda.ws';
    else if(process.env.RAILS_ENV == 'production')
      this._API_URL  = 'https://api.panalotto.koda.ws';

    this._BTN_LOGIN    = login_btn;

    this._API_VERSION  = '/api/v1'
    this._SIGNOUT_PATH = '/auth/sign_out'
    this._SIGNIN_PATH  = '/auth/sign_in'

    this._configuration()
  }


  get apiOrigin() {
    return this._API_URL;
  }


  _configuration() {
    auth.configure({
      apiUrl:                 this._API_URL + this._API_VERSION,
      signOutPath:            this._SIGNOUT_PATH,
      emailSignInPath:        this._SIGNIN_PATH,
      handleLoginResponse:    this._cleanAuth,
      tokenValidationPath:   '/auth/validate_token',
      handleTokenValidationResponse: this._clean2kAuth.bind(this),
    });
  }


  handleValidate() {
    if(!$.isEmptyObject($.auth.user) && $.auth.user.signedIn){
      this._asSignedIn()
    } else {
      this._asSignedOut()
    }
  }


  modalTriggers() {
    $(document).on('shown.bs.modal', '#sign_in', function (e) {
      const mobile_taggler = $('#mobile-nav-tagler');

      if(mobile_taggler.find('.navbar-toggler-icon').hasClass('active'))
        $('#mobile-nav-tagler').trigger('click');
    });


    $(document).on('hidden.bs.modal', '#sign_in', function (e) {
      if(!$.isEmptyObject($.auth.user) && $.auth.user.signedIn) {
        this._remove_signin_modal();
      }
    }.bind(this));
  }


  logTriggers() {
    this.signMeIn();
    this.signMeOut();
  }


  signMeIn(uname, password) {
    $('#form-signin').unbind()
    $(document).on('submit', '#form-signin', function(e) {
      e.preventDefault()
      $.auth.emailSignIn({
        authenticity_token: $('meta[name="csrf-token"]').attr("content"),
        username: $('#sign-uname').val(),
        password: $('#sign-pword').val()
      });
    }.bind(this));
  }


  _clean2kAuth(resp) {
    resp.user = resp.data
    delete resp.data;
    return this._cleanAuth(resp)
  }


  _cleanAuth(resp) {
    resp.user.numb = resp.user.id;

    delete resp.user.id;
    delete resp.user.created_at;
    delete resp.user.mobile_number;
    delete resp.user.provider;
    delete resp.user.recovery_code;
    delete resp.user.uid;
    delete resp.user.updated_at;
    delete resp.user.uuid;
    delete resp.user.verification_code;
    delete resp.user.verified_on;
    return resp.user;
  }


  signMeOut() {
    $('#nav-signout').unbind();
    $(document).on('click', '#nav-signout', function() {
      $.auth.signOut();
    });
  }


  pubSubCallbacks() {
    PubSub.clearAllSubscriptions();
    this._validationSuccess()
    this._validationError()

    this._signInError()
    this._authSignOutSuccess()
    this._authSignOutError()
  }


  _validationSuccess() {
    PubSub.subscribe('auth.validation.success', function(ev, msg) {
      this._asSignedIn();
    }.bind(this));
  }


  _validationError() {
    PubSub.subscribe('auth.validation.error', function(ev, msg) {
      this._asSignedOut()
    }.bind(this));
  }


  _authSignOutSuccess() {
    PubSub.subscribe('auth.signOut.success', function(ev, msg) {
      this._changeToSignIn();
      if(!$('#sign_in').length) {
        Rails.ajax({
          url: "/sign_in",
          type: "get",
          dataType: 'script',
          data: ""
        });
      }
    }.bind(this));
  }



  _authSignOutError() {
    PubSub.subscribe('auth.signOut.error', function(ev, msg) {
      // console.log('There was a problem with your sign out attempt. Please try again!');
    }.bind(this));
  }


  _signInError() {
    PubSub.subscribe('auth.emailSignIn.error', function(ev, msg) {
      Swal.fire({
        icon: 'error',
        text: msg.error,
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
      })
    });
  }


  _asSignedOut() {
    this._changeToSignIn();
    if($('#sign_in').length < 0) {
      Rails.ajax({
        url: "/sign_in",
        type: "get",
        dataType: 'script',
        data: ""
      });
    }
  }


  _asSignedIn() {
    this._changeToSignOut();

    if($('#sign_in').length && $('#sign_in').hasClass('show'))
      $('#sign_in').modal('hide');
    else
      this._remove_signin_modal()
  }


  _changeToSignIn() {
    if(window.history.state && window.history.state.state) {
      window.location.replace('/')
    } else {
      if(!$('body').hasClass('shop index'))
        $('#nav-sign').addClass('d-none')
      
      $('#nav-sign').html(
          '<a class="nav-link" data-target="#sign_in" data-toggle="modal" id="nav-sign-in"> \
            LOG IN\
          </a>'

      );
    }
  }


  _changeToSignOut() {
    $('#nav-sign').removeClass('d-none');
    $('#nav-sign').html(
      '<a class="nav-link" id="nav-signout"> \
        LOG OUT\
      </a>'
    );
  }

  _remove_signin_modal() {
    $('.modal-backdrop').remove();
    $('#sign_in').unbind().remove();
  }
}